/**
 * enum NotificationTypeEnum
 *
 * @var System Системное
 * @var Design Проектирование
 * @var Violation Предписания
 * @var Estimate Сметы
 */
export enum NotificationTypeEnum {
    System = 1,
    Design,
    Violation,
    Estimate,
}
