var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.user)?_c('div',{staticClass:"header__col_profile"},[(_vm.isReportCatalog)?_c('div',{staticClass:"user-desk__filter"},[_c('div',{staticClass:"user-desk"},[_c('div',{staticClass:"user-desk__filter-icon"},[_c('img',{attrs:{"src":require("@/assets/search_black_24dp.svg?inline")},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFilter.apply(null, arguments)}}})])]),_c('div',{class:{
                'header-profile_mobile-hide': true,
                'user-details__background': true,
                'user-details__background_open': _vm.isFilter,
            },on:{"click":_vm.hideFilter}}),_c('PrescriptionFilter',{class:{
                'prescription-filter': true,
                'prescription-filter_close': !_vm.isFilter,
            },attrs:{"hide-filter":_vm.hideFilter,"isMobile":""}})],1):_vm._e(),_c('NotificationPanel'),_c('div',{staticClass:"user-desk"},[_c('div',{staticClass:"user-desk__user-card",on:{"click":function($event){$event.stopPropagation();return _vm.showDetails.apply(null, arguments)}}},[_c('VUserAvatar',{staticClass:"header-profile_desk-hide",attrs:{"user":_vm.user}}),_c('img',{staticClass:"header-profile_mobile-hide",attrs:{"src":require("@/assets/menu-24px.svg?inline")}})],1),_c('div',{staticClass:"user-desk__user-details"},[_c('div',{class:{
                    'header-profile_mobile-hide': true,
                    'user-details__background': true,
                    'user-details__background_open': _vm.toggleDetailsStatus,
                }}),_c('VAccountDesk',{attrs:{"hide-details":_vm.hideDetails,"toggle-details-status":_vm.toggleDetailsStatus,"user":_vm.user}})],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }