import Http from '../Http';

// Interfaces
import ITransformedValue from '../Values/ITransformedValue';
import INotification from '@/ship/Models/INotification';
import { GetRequestParams } from '@/ship/Requests/RequestParams';

// Services
import ErrorService from '@/utils/ErrorService';

export default class NotificationService {
    // API
    public static async getNotifications(params: GetRequestParams = { limit: 0 }) {
        return await Http.get<ITransformedValue<INotification[]>>(`/user/notifications`, { params })
            .then((response) => response.data.data)
            .catch(ErrorService.handleApiError);
    }

    public static async readNotification(notificationId: string) {
        await Http.get(`/user/notification/${notificationId}/read`)
            .then((response) => response.data.data)
            .catch(ErrorService.handleApiError);
    }
}
